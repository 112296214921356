<template>
	<div id="top" class="container" :class="$route.name && page.headingImage ? '' : 'no-slider'">
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />
		</Head>

		<navigation />

		<slot />

		<footers @click-consent-open="openCookieConsent" />
		<pop-up v-if="page && defaults[locale].website.popupContent" :content="defaults[locale].website.popupContent" />
		<cookie-consent ref="cookiebanner" :item="defaults[locale].cookieConsent" />
		<to-top />
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
await fetchPage();

const cookiebanner = ref(null);

const openCookieConsent = () => {
	window.CookieFirst && cookiebanner.value.openConsent();
};

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.hotelName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.hotelName || '',
});

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'Hotel',
				'@id': `${config.public.siteUrl}#hotel`,
				image: website.structuredImage,
				url: config.public.siteUrl,
				name: website.hotelName,
				logo: `${config.public.siteUrl}/images/logo-ocean-house-full-black.png`,
				telephone: website.phone,
				email: website.email,
				address: {
					'@type': 'PostalAddress',
					streetAddress: `${website.street} ${website.number}, ${website.city}`,
					postalCode: website.postalCode,
				},
			}),
		},
	],
});
</script>
