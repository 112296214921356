<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3">
					<h3>{{ defaults[locale].website.hotelName }}</h3>
					<p>
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<br />
						<span>{{ defaults[locale].website.country }}</span>
						<br />
					</p>
				</div>
				<div class="columns column3">
					<h3>{{ $t('footerContact') }}</h3>
					<p>
						<font-awesome-icon icon="fa-regular fa-phone" size="1x" /> &nbsp;
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<br />
						<font-awesome-icon icon="fa-regular fa-envelope" size="1x" /> &nbsp;
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h3>{{ $t('footerNavigate') }}</h3>
					<p class="footer-nav">
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
						<a href="#" @click="$emit('clickConsentOpen')">{{ $t('consentOpen') }}</a>
						<br />
						<a
							v-if="locale === 'nl'"
							href="https://jobs.excitehotels.nl/"
							target="_blank"
							title="Vacatures"
						>
							Vacatures
						</a>
					</p>
				</div>
			</div>
		</footer>
		<footer class="author">
			<div class="row">
				<div class="columns column12 align-center">
					<hr />
					<p>
						<a :href="$t('siteByUrl')" target="_blank" rel="noopener noreferrer"> Hotel Website Design </a>
						by:
						<a :href="$t('siteByUrl')" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="Site by" />
							Porter
						</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footermenu } = await useWebsiteFetch('footermenu');
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();

defineEmits(['clickConsentOpen']);
</script>

<style lang="scss" scoped>
footer {
	background: $footer-background-color;
	color: $footer-text-color;
	padding: 100px 0 25px;
	font-size: 0.85rem;

	h3 {
		color: #fff;
	}
}

hr {
	background: #f4f2ec;
	width: 100%;
	border: none;
	height: 2px;
	margin: 0 auto 25px;
}

a {
	color: $footer-link-color;
	text-decoration: none;

	&:hover {
		color: $footer-text-color;
	}
}

.footer-logo {
	float: left;
	background: url('~/assets/images/logo-ocean-house-full-white.png') no-repeat center center;
	width: 112px;
	height: 118px;
	background-size: 112px;
}

.author {
	background: $author-background-color;
	padding: 20px 0;
	color: $footer-link-color;

	p {
		margin: 0;
		font-size: 12px;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}
}

@media (max-width: 900px) {
	footer .columns {
		margin: 20px auto;
		text-align: left;
	}

	footer.author .columns {
		text-align: center;
	}

	footer .row:last-child .columns:last-child a {
		margin: 0;
	}
}

@media (max-width: 600px) {
	footer {
		.columns {
			text-align: center;
		}

		.socials {
			justify-content: center;
		}
	}

	.footer-logo {
		margin: 0 auto;
		float: none;
	}
}
</style>
