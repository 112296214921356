<template>
	<nav v-click-outside="hideMenu" class="main" :class="{ active: showMenu }">
		<div class="row">
			<div class="columns column12">
				<nuxt-link class="logo" :class="page.headingImage ? '' : 'black'" :to="localePath('/')" />
				<div class="nav-container">
					<ul class="main-nav">
						<li
							v-for="item in topmenu[locale]"
							:key="item.filename"
							:class="{ 'has-dropdown': item.submenu.length }"
						>
							<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
								{{ item.header }}
							</nuxt-link>
							<submenu
								v-if="item.submenu.length"
								:hide-all="!showMenu"
								:item="item"
								:subitems="item.submenu"
								@hide-menu="hideMenu"
							/>
						</li>
					</ul>
					<div class="contact-info">
						<p>
							<strong>{{ defaults[locale].website.hotelName }}</strong>
							<br />
							<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
							<br />
							<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
							<br />
							<span>{{ defaults[locale].website.country }}</span>
							<br />
							<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
								<font-awesome-icon icon="fa-regular fa-phone" size="1x" />
								<span>{{ defaults[locale].website.phone }}</span>
							</a>
							<br />
							<a :href="`mailto:${defaults[locale].website.email}`">
								<font-awesome-icon icon="fa-regular fa-envelope" size="1x" />
								<span>{{ defaults[locale].website.email }}</span>
							</a>
						</p>
						<social-media :socials="socials" />
					</div>
					<ul v-if="page" class="lang-nav">
						<li v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
							<nuxt-link class="flag" :class="item.language" :to="`${item.filename}`" @click="hideMenu">
								{{ $t(item.language.toLowerCase()) }}
							</nuxt-link>
						</li>
					</ul>
				</div>
				<div class="buttons">
					<book-button class="button button-cta reserve-now right">
						<font-awesome-icon icon="fa-regular fa-calendar-days" size="1x" />
						<span>{{ $t('bookNow') }}</span>
					</book-button>

					<div class="menu-button button right button-en" :class="{ active: showMenu }" @click="toggleMenu">
						<font-awesome-icon v-show="showMenu" icon="fa-regular fa-times" size="1x" />
						<font-awesome-icon v-show="!showMenu" icon="fa-regular fa-bars" size="1x" />
						<span>{{ $t('menu') }}</span>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const showMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const toggleMenu = () => {
	showMenu.value = !showMenu.value;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.menu-button');
	if (window.pageYOffset >= 200) {
		fixedElements.forEach((el) => el.classList.add('dark'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('dark'));
	}
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
nav {
	width: 100%;
	padding: 15px 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	transition: all 0.3s ease-in-out;

	.row {
		overflow: visible;
	}
}

.no-slider nav {
	background: #a79f88;
}

.logo {
	float: left;
	background: url('~/assets/images/logo-ocean-house-full-white.png') no-repeat center center;
	width: 112px;
	height: 118px;
	background-size: 112px;

	&:hover {
		color: #fff;
	}

	&.black {
		color: #111;
	}

	strong {
		font-size: 30px;
		letter-spacing: 2px;
	}

	img {
		max-width: 100px;
	}
}

.main-nav {
	list-style: none;
	text-align: left;
	font-weight: 500;
	line-height: 26px;
	width: 55%;
	float: left;
	visibility: visible;

	& > li {
		display: block;
		float: left;
		margin: 10px 0;
		width: 100%;
	}

	a {
		color: #19161a;
		position: relative;
		padding: 7px 0 7px 50px;
		font-family: $heading-font;
		font-weight: 200;
		font-size: $h4-font-size;
		text-decoration: none;
		transition: color 0.3s ease-in-out;
		text-transform: uppercase;

		&.active,
		&:hover {
			color: #a79f88;
		}

		&::before {
			content: '';
			display: block;
			opacity: 0;
			position: absolute;
			left: 0;
			top: 10px;
			background: url('~/assets/images/logo-ocean-house-icon-only-gold.png') no-repeat center center;
			background-size: 39px;
			width: 39px;
			height: 28px;
			transition: opacity 0.3s ease-in-out;
		}

		&.active::before {
			opacity: 1;
		}
	}
}

.contact-info {
	float: left;
	width: 45%;
	color: $heading-color;
	font-size: 16px;

	svg {
		margin-right: 5px;
	}

	a {
		color: #a79f88;
	}
}

.lang-nav {
	list-style: none;
	text-align: left;
	float: left;
	width: 50%;
	line-height: 26px;
	padding: 30px 0 0 50px;

	a {
		color: #000;

		&.router-link-exact-active,
		&:hover {
			color: $cta-color;
		}
	}

	.flag::before {
		content: '';
		width: 16px;
		position: relative;
		top: 2px;
		margin-right: 7px;
		height: 16px;
		display: inline-block;
	}

	.flag.en::before {
		background: url('~/assets/images/en.png') no-repeat;
	}

	.flag.nl::before {
		background: url('~/assets/images/nl.png') no-repeat;
	}

	.flag.de::before {
		background: url('~/assets/images/de.png') no-repeat;
	}

	.flag.fr::before {
		background: url('~/assets/images/fr.png') no-repeat;
	}

	.flag.es::before {
		background: url('~/assets/images/es.png') no-repeat;
	}
}

.nav-container {
	position: fixed;
	top: 0;
	right: -900px;
	bottom: 0;
	background: rgba(255 255 255 / 84%);
	backdrop-filter: blur(34px);
	min-width: 36%;
	width: 800px;
	max-width: 100%;
	padding: 150px 50px 20px;
	z-index: 100;
	box-shadow: 0 0 20px rgb(0 0 0 / 10%);
	transition: right 0.3s ease-in-out;
}

.active .nav-container {
	right: 0;
	transition: right 0.5s ease-in-out;
}

.nav-overlay {
	display: none;
	position: fixed;
	inset: 0;
	background: rgb(0 0 0 / 30%);
	z-index: 99;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;

	&.active {
		display: block;
		opacity: 1;
	}
}

.no-slider nav .buttons .menu-button {
	color: $cta-color;
	border-color: $cta-color;
	background: #fff;
}

nav .buttons {
	position: fixed;
	top: 15px;
	right: 20px;
	z-index: 101;

	.button {
		margin-top: 4px;
		margin-left: 6px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-right: 10px;
			width: 1em;
		}
	}

	.menu-button {
		cursor: pointer;
		background: none;
		color: #fff;
		padding: 15px 20px;
		border: 1px solid #fff;

		&:hover {
			color: $cta-color;
			border-color: $cta-color;
		}

		&.active,
		&.dark {
			color: $cta-color;
			border-color: $cta-color;
			background: #fff;

			i::before {
				content: '\f00d';
				font-weight: 300;
				color: $cta-color;
			}
		}

		svg {
			font-size: 20px;
		}
	}
}

@media (max-height: 740px) {
	.nav-container {
		overflow-y: scroll;
	}

	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		z-index: 1;
	}

	.main-nav {
		z-index: 2;
		position: relative;
	}
}

@media (max-width: 800px) {
	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		float: left;
		margin-top: 30px;
	}
}

@media (max-width: 600px) {
	.nav-container {
		padding: 100px 25px 25px 5px;
	}

	.main-nav,
	.contact-info,
	.lang-nav {
		width: 100%;
		padding-left: 25px;
	}

	.main-nav a {
		font-size: 1.2em;
		padding-left: 0;
	}

	.contact-info {
		margin: 25px auto 0;
	}

	.lang-nav {
		margin-top: 0;
	}

	nav .buttons .button {
		padding: 15px 20px;

		svg {
			margin-right: 0;
			font-size: 16px;
		}

		span {
			display: none;
		}
	}
}
</style>
